/* :root{

} */
/* Reset */
html{
  font-size: 10px;
  box-sizing: border-box;
  font-family:'DM Sans', sans-serif;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  font-size: 100%;
  line-height: 1;
}

html,
body,
div,
span,
h1,
h2,
header
{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family:'DM Sans', sans-serif;
  vertical-align: baseline;
  text-decoration: none;
}

/* Login */
.login_container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.login_container_left {
  width: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_container_left img {
  width: 100%;
  padding: 10rem 10rem 10rem 10rem;
}

.login_container_right {
  width: 50%;
  height: auto;
  padding-bottom: 3.4rem;
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_container_right form {
  color: white;
  font-size: 3.7rem;
  width: 40%;
}

.login_container_right input {
  display: block;
  margin-top: 3rem;
  width: 100%;
  height: 3rem;
  text-align: left;
  font-size: 15px;
  padding-left: 15px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-family:'DM Sans', sans-serif;
}

.errorLogin{
  font-size: 16px;
  font-family:'DM Sans', sans-serif;
  margin-top: 30px;
  color: #d51313;
}
/* Home */

.home-text{
  width: 30rem;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  color: rgb(101, 99, 99);
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
}

.button-container {
	width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top:4rem;
}

.button {
  font-family:'DM Sans', sans-serif;
	display: flex;
	align-items: center;
	width: 20rem;
	height: 5rem;
	background: #ff5900;
	color:  #fff;
	font-size: 1.6rem;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: uppercase;
	transition: .3s ease all;
	border-radius: 1rem;
	position: absolute;
	overflow: hidden;
}

.button span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}
.button span {
	transition: .4s ease-in-out all;
	position: absolute;
	left: 20%;
}

.button .icon {
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 2;
	left: -4rem;
	transition: .3s ease-in-out all;
	opacity: 0;
}

.button img {
	color: #fff;
	width: 3rem;
	height: 3rem;
}

.button:hover .icon {
	left: calc(100% - 5rem);
	opacity: 1;
}

.button:hover span {
	left: 2rem;
}
/* Nav */
.container{
  justify-content: space-between;
  width: 100%;
  padding: 1rem 5rem ;
  display: flex;
  background: #fff;
  box-shadow: 0 .3rem 2rem rgba(108, 62, 62, 0.25);
}
.logo-home{
  height: 3.5rem;
}

.button-session{
  font-family:'DM Sans', sans-serif;
  background-color: #ff5900;
  border: none;  
  border-radius: .5rem;
  color: #fff;
  padding: .5rem 1rem  ;
  text-decoration: none;
  margin: 1rem ;
  font-size: 1rem;
  cursor: pointer;
}

.button-session:hover{
  background-color: #f87f3d;
}

.errordownload{
  font-family:'DM Sans', sans-serif;
  color: #d51313;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10rem;
}
.table-container {
  margin: 5rem auto 1rem auto;
  border-collapse: collapse;
  font-size: .9rem;
  width: 90%;
  border-radius: .3rem .3rem 0 0;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
}
.table-container thead tr {
  background-color: #ff5900;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  font-family:'DM Sans', sans-serif;
}
.table-container th,
.table-container td {
  padding: 1rem 1.2rem;
}
.table-container tbody tr {
  border-bottom: 0.1rem solid #dddddd;
}
.table-container tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.table-container tbody tr:last-of-type {
  border-bottom: 0.1rem solid  #ff5900;
}
.container-pagination{
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 35rem;
  padding-right:10rem ;
}
.pagination {
  padding: .2rem 1.8rem .8rem 3rem;
  background-color: #fdd1b9;
  height: 2.2rem;
  border-radius: .5rem; 
  
}
.pagination select {
  position: absolute;
  font-size: 1rem;
  font-weight: normal;
  margin-left: -1.4rem;
  border: none;
  background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  appearance: none;
}
.pagination select:active, .pagination select:focus {
  
  outline: none;
  box-shadow: none;
}
.pagination:after {
  content: "";
  position: absolute;
  margin-top: .8rem;
  border-top: 5px solid #ff5900;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.selectPage{
  width: 3.5rem;
  height: 2rem;
  cursor: pointer;
}
.img-button{
  width: 2rem;
  height: 1.5rem;
  margin: .4rem 1rem 1rem 1rem;
  cursor: pointer;
}


@media (max-width:959px) {

.login_container {
      display: block;
  }
  .login_container_left {
      width: 100%;
      height: auto;

  }
  .login_container_right {
      width: 100%;
      height: 100%;
      align-items: flex-start;
      padding-top: 7rem;
  }
  .login_container_right form {
      margin-left: 0;    
      text-align: center;
  }
}
.none{
  display: none;
}
.totalUsers{
  
  margin-left:10rem ;
  /* width: 12rem; */
}
.total-container{
  display: flex;
  margin-left: 5rem;
}
.prev-container {
background-color: inherit;
border: none;
}